import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../authConfig';

const TestFunctionCall = () => {
    const { instance } = useMsal();
    const [courseData, setCourseData] = useState('');
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        const fetchCourseData = async () => {
            console.log('activeAccount', activeAccount);

            if (!activeAccount) {
                console.error('No active account! Verify a user is signed in and setActiveAccount has been called.');
                return;
            }

            const request = {
                scopes: ['https://unboundeducationapp.b2clogin.com//' + msalConfig.auth.clientId + '.default'], // Replace with your Azure function app's client ID and add /.default for default scope
                account: activeAccount
            };

            try {
                // Try to acquire token silently
                const response = await instance.acquireTokenSilent(request);

                console.log('token response', response);
                await getCourseData(response.idToken);
                
            } catch (error) { 
                if (error.name === "InteractionRequiredAuthError") {
                    try {
                        const response = await instance.acquireTokenPopup(request);
                        // Repeat the fetch call with the new token
                        await getCourseData(response.idToken);
                    } catch (err) {
                        console.error('Failed to acquire token:', err);
                    }
                } else {
                    console.error('Failed to fetch course data:', error);
                    setCourseData('Failed to load course data.' + error);
                }
            }
        };

        fetchCourseData();
    }, [instance, activeAccount]); // Dependency array includes instance and activeAccount to re-run effect if these change

    const getCourseData = async (idToken) => {
        // Fetch the course data with the token
        const fetchResponse = await fetch('https://unboundeducationapi.azurewebsites.net/api/GetCourse', {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });

        console.log('fetchResponse', fetchResponse);

        const data = await fetchResponse.json();
        const dataString = JSON.stringify(data, null, 2);
        setCourseData(dataString);
    };

    return (
        <div>
            <h1>Course Data</h1>
            <pre>{courseData}</pre>
        </div>
    );
};

export default TestFunctionCall;
