import FullWidthSubHeading from './FullWidthSubHeading';
import './NumberBox2.css';

const NumberBox2 = ({ title, items, styleOption, dots }) => {
    // Define header colors based on the style option
    const colours = ['ue-yellow-background', 'ue-orange-background', 'ue-light-blue-background', 'ue-mid-blue-background', 'ue-teal-background'];
    const colour = colours[styleOption] || colours[0]; // Default to the first color if out of range

    return (
        <>
            <div className={"ue-number-box-2"}>
                <FullWidthSubHeading
                    title={title}
                    styleOption={styleOption}
                    dots={dots ?? 4} />
                <div className="ue-nb2-inner">
                    {items.map((item, index) => (
                        <div id={index} className="ue-nb2-row">
                            <div className={"ue-nb2-number ue-small-shadow " + colour}>{index + 1}</div>
                            <p className="ue-nb2-item">{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
};

export default NumberBox2;