import React from 'react';
import './CardGrid.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faAward, faUserGraduate, faPersonChalkboard, faBookOpen, faTree, faPerson, faChild, faChildReaching, faChildren, faFaceSmile, faBoxOpen } from '@fortawesome/free-solid-svg-icons'

const CardGrid = ({ cards }) => {
    const assignBorderStyle = (index) => {
        const styleNumber = index % 4 + 1; // This will cycle through 1, 2, 3, 4
        return `uc-cg-card-border-${styleNumber}`; // This matches the class names defined in the CSS
    };

    const assignIconColor = (index) => {
        const styleNumber = index % 4 + 1; // This will cycle through 1, 2, 3, 4
        return `uc-cg-icon-color-${styleNumber}`; // This matches the class names defined in the CSS
    };

    const getIcon = (iconType) => {
        switch (iconType) {
            case 'mortar':
                return <FontAwesomeIcon icon={faGraduationCap} />;
            case 'award':
                return <FontAwesomeIcon icon={faAward} />;
            case 'graduate':
                return <FontAwesomeIcon icon={faUserGraduate} />;
            case 'chalkboard':
                return <FontAwesomeIcon icon={faPersonChalkboard} />;
            case 'book':
                return <FontAwesomeIcon icon={faBookOpen} />;
            case 'tree':
                return <FontAwesomeIcon icon={faTree} />;
            case 'person':
                return <FontAwesomeIcon icon={faPerson} />;
            case 'child':
                return <FontAwesomeIcon icon={faChild} />;
            case 'reachingchild':
                return <FontAwesomeIcon icon={faChildReaching} />;
            case 'children':
                return <FontAwesomeIcon icon={faChildren} />;
            case 'smile':
                return <FontAwesomeIcon icon={faFaceSmile} />;
            case 'box':
                return <FontAwesomeIcon icon={faBoxOpen} />;
            default:
                return <FontAwesomeIcon icon={faGraduationCap} />;
        }
    };

    return (
        <div className="ue-cg-container">
            {cards.map((card, index) => (
                <div key={index} className={'uc-cg-card ' + assignBorderStyle(index)}>
                    <div className={'uc-cg-icon ' + assignIconColor(index)}>
                        {getIcon(card.icon)}
                    </div>
                    <div className="uc-cg-copy">
                        <h4 className="uc-cg-title">
                            {card.title}
                        </h4>
                        <p className="uc-cg-text">
                            {card.text}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CardGrid;
