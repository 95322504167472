import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import TopicContainer from '../components/TopicContainer';
import { PropagateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const Topic = () => {
    const { topicID } = useParams();
    const { fetchData, data, error } = useAuthenticatedFetch();
    const topicEndpoint = `https://unboundeducationapi.azurewebsites.net/api/GetTopic`;
    const navigate = useNavigate();

    useEffect(() => {
        const loadCourse = async () => {
            await fetchData(topicEndpoint, topicID, { method: 'GET' }, navigate);
        };

        loadCourse();
    }, [fetchData, topicID, topicEndpoint, navigate]);
    
    if (error) {
        return <div>Error loading topic data: {error}</div>;
    }
    
    return (
        <>
            {data === null ? (
                <PropagateLoader color="#2CC0DB" />
            ) : (
                <TopicContainer
                    topicTitle={data?.topicTitle}
                    topicDescription={data?.description}
                    activities={data?.activities}
                    styleOption={1}
                    />
            )}
        </>
    );
}

export default Topic;