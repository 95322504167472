import React from 'react';
import './PreparationBox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson } from '@fortawesome/free-solid-svg-icons';

const PreparationSoloBox = ({ learnerSteps }) => {
    return (
        <div className="ue-prep-box" >
            <div className="ue-prep-row">
                <div className="ue-prep-type-box ue-teal-background">
                    <FontAwesomeIcon className="ue-prep-child-icon" icon={faPerson} />
                    <h4 className="ue-prep-type-label">Learner</h4>
                </div>
                <div className="ue-prep-steps-box ue-white-background">
                    <ol>
                        {learnerSteps.map((step, index) => (
                            <li id={"learner" + index}>{step}</li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    )
};

export default PreparationSoloBox;
