import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const AuthenticatedRoute = ({ children }) => {
    const { accounts } = useMsal();
    const isAuthenticated = accounts.length > 0;

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default AuthenticatedRoute;
