import React, { useState } from 'react';
import './ThemeContainer.css'; // Import the corresponding CSS file

const ThemeContainer = ({ themeTitle, themeDescription, topics, styleOption }) => {
    // Define header colors based on the style option
    const headerColorClasses = ['ue-mid-blue-background', 'ue-yellow-background', 'ue-white-background'];
    const headerColorClass = headerColorClasses[styleOption] || headerColorClasses[0]; // Default to the first color if out of range
    const sideBarColorClasses = ['ue-teal-background', 'ue-orange-background', 'ue-yellow-background'];
    const sideBarColorClass = sideBarColorClasses[styleOption] || sideBarColorClasses[0];
    const [topicVisible, setTopicVisible] = useState(false);

    const toggleTopic = () => {
        setTopicVisible(!topicVisible);
    }

    const openTopic = (topicID) => {
        window.location.href = "/topic/" + topicID;
    }

    return (
        <>
        <div className={"ue-theme-box ue-mid-shadow " + headerColorClass}>
            <h3 className="ue-theme-box-title">{themeTitle}</h3>
            <p className="ue-theme-box-text">{themeDescription}</p>
            {topicVisible ?
                <button className="ue-secondary-button" onClick={() => toggleTopic()}>
                    Hide topics
                </button>
            :
                <button className="ue-primary-button" onClick={() => toggleTopic()}>
                    Show topics
                </button>
            }
            
            </div>
            
            {topicVisible && topics.map((topic, index) => (
                <div className="ue-topic-overview-box" key={topic.id}>
                    <div className={"ue-small-shadow " + sideBarColorClass + (topics.length - 1 === index ? " ue-topic-list-end" : "") + (index === 0 ? " ue-topic-list-element-first" : " ue-topic-list-element")}>
                        <div className="ue-topic-index ue-white-background">{index + 1}</div>
                    </div>
                    <div className={"ue-topic-overview-details" + (topics.length - 1 === index ? " ue-topic-overview-details-last" : "")}>
                        <div>
                            <h4 className="ue-topic-overview-title">{topic.topicTitle}</h4>
                            <p>{topic.description}</p>
                        </div>
                        <button className="ue-primary-button ue-topic-button" onClick={() => openTopic(topic.id)}>
                            View Topic
                        </button>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ThemeContainer;
