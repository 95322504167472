import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import NumberBox2 from '../components/NumberBox2';
import ActivityHeaderBox from '../components/ActivityHeaderBox';
import PreparationPairBox from '../components/PreparationPairBox';
import PreparationSoloBox from '../components/PreparationSoloBox';
import FullWidthSubHeading from '../components/FullWidthSubHeading';
import { PropagateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const Activity = () => {
    const { activityID } = useParams();
    const { fetchData, data, error } = useAuthenticatedFetch();
    const topicEndpoint = `https://unboundeducationapi.azurewebsites.net/api/GetActivity`;
    const navigate = useNavigate();

    useEffect(() => {
        const loadCourse = async () => {
            await fetchData(topicEndpoint, activityID, { method: 'GET' }, navigate);
        };

        loadCourse();
    }, [fetchData, activityID, topicEndpoint, navigate]);

    if (error) {
        return <div>Error loading activity data: {error}</div>;
    }

    return (
        <>
            {data === null ? (
                <PropagateLoader color="#6CEEEF" />
            ) : (
                    <div className="ue-page-container ue-mid-shadow">
                        <ActivityHeaderBox
                            title={data.Title}
                            outcome={data.LearningGoal}
                            resources={data.MaterialsNeeded}
                            description={data.Description}
                            activityType={data.Type}
                            userType={data.UserSuitability}
                        />
                        <FullWidthSubHeading
                            title={"Advice on running the activity"}
                            styleOption={0}
                            dots={1}
                            isHeader={false} />
                        <p className="ue-section-text">{data?.Advice}</p>
                        <FullWidthSubHeading
                            title={"Preparation"}
                            styleOption={4}
                            dots={2}
                            isHeader={false} />
                        {data.UserSuitability === 'solo' ?
                            <PreparationSoloBox
                                learnerSteps={data.Steps.Preparation}
                                />
                            :
                            <PreparationPairBox
                                adultSteps={data.Steps.Preparation.Parent}
                                learnerSteps={data.Steps.Preparation.Student}
                                />
                        }
                        <NumberBox2
                            title="Activity Steps"
                            items={data?.Steps.ActivitySteps}
                            styleOption={1}
                            dots={3}
                        />
                        <NumberBox2
                            title="Reflection"
                            items={data?.Steps.Discussion}
                            styleOption={3}
                            dots={4}
                        />
                        <FullWidthSubHeading
                            title={"Next Steps and Extensions"}
                            styleOption={0}
                            dots={5}
                            isHeader={false} />
                        <div className="ue-section-text">
                            <h4>Want to take it further? Try some of these...</h4>
                            <ol>
                                {data.Steps.ExtendedActivities.map((step, index) => (
                                    <li id={index}>{step}</li>
                                ))}
                            </ol>
                        </div>
                        <FullWidthSubHeading
                            title={"Assessment"}
                            styleOption={4}
                            dots={6}
                            isHeader={false} />
                        <p className="ue-section-text">Coming soon: Example exam questions relevant to this activity with specimen answers at different levels</p>
            </div >
            )}
        </>
    );
}

export default Activity;