import React from 'react';
import logo from '../images/unbound-logo-icon.png';
import './ActivityHeaderBox.css';
import CardGrid from './CardGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson } from '@fortawesome/free-solid-svg-icons';

const ActivityHeaderBox = ({ title, outcome, resources, description, activityType, userType }) => {

    const cards = [
        {
            icon: 'mortar',
            title: 'Learning Outcome',
            text: outcome
        },
        {
            icon: 'box',
            title: 'Resources Required',
            text: resources
        }
    ];

    const getUserType = (userType) => {
        console.log('userType', userType);
        switch (userType) {
            case 'solo':
                return (
                    <div className="ue-ahb-user-type">
                        <FontAwesomeIcon className="ue-ahb-child-icon" icon={faPerson} />
                        <h3 className="ue-ahb-user-type-label">Solo activity: {activityType}</h3>
                    </div>
                );
            case 'pair':
                return (
                    <div className="ue-ahb-user-type">
                        <FontAwesomeIcon className="ue-ahb-adult-icon" icon={faPerson} />
                        <FontAwesomeIcon className="ue-ahb-child-icon" icon={faPerson} />
                        <h3 className="ue-ahb-user-type-label">Pair activity: {activityType}</h3>
                    </div>
                );
            default:
                return <></>;
        }
    };

    return (
        <>
        <div className="ue-ahb-container">
            <div className="ue-ahb-column">
                <div className="ue-orange-background ue-ahb-title-container">
                    <h1 className="ue-ahb-title">{title}</h1>
                        {getUserType(userType)}
                </div>
                <p className="ue-ahb-text">{description}</p>
            </div>
            <img src={logo} alt="Unbound Education logo" className="ue-ahb-image" />
            </div>
            <CardGrid cards={cards} />
        </>
    );
};

export default ActivityHeaderBox;