import React from 'react';
import './Hero.css'; // Import the corresponding CSS file
import logo from '../images/unbound-logo-icon.png';


const Hero = ({ title, text, showButton, loginRedirect, imageSize }) => {

    return (
        <div className="ue-hero-container">
            <div className="ue-hero-column">
                <div className="ue-orange-background ue-hero-title-container">
                    <h1 className="ue-hero-title">{title}</h1>
                </div>
                <p className="ue-hero-text">{text}</p>
                {showButton ?
                    <div className="ue-hero-signup">
                        <button className="ue-primary-button ue-hero-button" onClick={() => loginRedirect()}>
                            Sign up for free
                        </button>
                        <p>Already have an account? <span className="ue-link" onClick={() => loginRedirect()}>Log in</span></p>
                    </div>
                    :
                    ''
                    }
            </div>
            <img src={logo} alt="Unbound Education logo" className={'ue-hero-image-' + imageSize} />
        </div>
    );
};

export default Hero;
