import React from 'react';
import './TopicContainer.css'; // Import the corresponding CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson } from '@fortawesome/free-solid-svg-icons';

const TopicContainer = ({ topicTitle, topicDescription, activities, styleOption }) => {
    // Define header colors based on the style option
    const headerColorClasses = ['ue-mid-blue-background', 'ue-yellow-background', 'ue-white-background'];
    const headerColorClass = headerColorClasses[styleOption] || headerColorClasses[0]; // Default to the first color if out of range
    const sideBarColorClasses = ['ue-teal-background', 'ue-orange-background', 'ue-yellow-background'];
    const sideBarColorClass = sideBarColorClasses[styleOption] || sideBarColorClasses[0];

    const openActivity = (id) => {
        window.location.href = "/activity/" + id;
    };

    const getUserSuitability = (userSuitability) => {
        return (
            <>
            <div className="ue-user-suitability">
            {userSuitability === 'pair' ? 
                <div className="ue-topic-user-box">
                    <FontAwesomeIcon className="ue-topic-user-adult-icon" icon={faPerson} />
                    <FontAwesomeIcon className="ue-topic-user-child-icon" icon={faPerson} />
                    <h4 className="ue-topic-user-label">Pair Activity</h4>
                </div>
            : 
                <div className="ue-topic-user-box">
                    <FontAwesomeIcon className="ue-topic-user-child-icon" icon={faPerson} />
                    <h4 className="ue-topic-user-label">Solo Learner</h4>
                </div>
            }
            </div>
            </>
        )
    }

    return (
        <>
            <div className={"ue-topic-box ue-mid-shadow " + headerColorClass}>
                <h3 className="ue-topic-box-title">{topicTitle}</h3>
                <p className="ue-topic-box-text">{topicDescription}</p>
            </div>

            {activities.map((activity, index) => (
                <div className="ue-activity-overview-box" key={activity.id}>
                    <div className={"ue-small-shadow " + sideBarColorClass + (activities.length - 1 === index ? " ue-activity-list-end" : "") + (index === 0 ? " ue-activity-list-element-first" : " ue-activity-list-element")}>
                        <div className="ue-activity-index ue-white-background">{index + 1}</div>
                    </div>
                    <div className={"ue-activity-overview-details" + (activities.length - 1 === index ? " ue-activity-overview-details-last" : "")}>
                        <div className="ue-activity-overview-description">
                            <div>
                                <h3 className="ue-activity-overview-title">{activity.title}</h3>
                                <p>{activity.description}</p>
                                <p className="ue-resources-needed">Resources needed: {activity.materialsNeeded}</p>
                            </div>
                        </div>
                        <div className="ue-activity-overview-endstop ue-mid-blue-background">
                            <h4>{activity.type}</h4>
                            {getUserSuitability(activity.userSuitability)}
                            <button className="ue-primary-button ue-activity-button" onClick={() => openActivity(activity.id)}>
                                View Activity
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default TopicContainer;
