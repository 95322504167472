import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';
import { Footer } from './Footer';

export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar />
            <div className="ue-main-content">
            {props.children}
            </div>
            <AuthenticatedTemplate>
                <Footer/>
            </AuthenticatedTemplate>
        </>
    );
};
