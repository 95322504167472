import React, { useState, useRef, useEffect } from 'react';
import './DropdownMenu.css';

// DropdownMenu component
const DropdownMenu = ({ items, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    // Add event listener when the dropdown is open
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]); // This effect should run when isOpen changes

  return (
      <div className="ue-dropdown" ref={dropdownRef}>
          <button className="ue-dropdown-button ue-mid-shadow" onClick={toggleDropdown}>
        {title}
      </button>
      {isOpen && (
              <ul className="ue-dropdown-list ue-small-shadow">
          {items.map((item, index) => (
              <li key={index} className="ue-dropdown-item" onClick={() => {
              item.onClick();
              setIsOpen(false); // Close the dropdown after click
            }}>
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
