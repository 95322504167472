import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import Hero from '../components/Hero';
import CardGrid from '../components/CardGrid';
import NumberBox2 from '../components/NumberBox2';
import FullWidthSubHeading from '../components/FullWidthSubHeading';
import { loginRequest } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';

export const Home = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const navigate = useNavigate();

    const loginRedirect = () => instance.loginRedirect(loginRequest).catch((error) => console.log(error))

    const cards = [
        {
            icon: 'reachingchild',
            title: 'Learning as a Joyful Activity',
            text: 'We redefine learning as an engaging activity rather than a conventional lesson, making education a joyful and exciting part of your child\'s day.'
        },
        {
            icon: 'mortar',
            title: 'Empowering Home Educators',
            text: 'By providing structured yet flexible activities, we empower you to be the best home educator, offering the tools and resources to complement your unique teaching style.'
        },
        {
            icon: 'chalkboard',
            title: 'Tailored to Your Child\'s Unique Journey',
            text: 'Understanding that you know your child best, we offer a framework that supports your intuition with structured activities, allowing you to tailor the educational experience to fit your child\'s needs perfectly.'
        },
        {
            icon: 'children',
            title: 'Inclusive Education for All',
            text: 'Tailored solutions for School Avoiders and adaptations for various Special Educational Needs (SEN) ensure every child has access to education that respects their pace and learning style.'
        },
        {
            icon: 'graduate',
            title: 'Customised Learning Experience',
            text: 'With activity plans mapped directly to GCSE and the national curriculum, designed specifically for home education, your child receives a learning experience that\'s both structured and flexible, enabling them to reach their full potential.'
        },
        {
            icon: 'tree',
            title: 'Broad Educational Spectrum',
            text: 'Our platform goes beyond the basics of STEM, offering a wide range of subjects to ensure a well-rounded education, catering to every interest and sparking curiosity in new areas of learning.'
        }
    ];

    const steps = [
        'Choose your subject and find the theme and topic that interests you',
        'Choose the right activity for your learner from one of the many activities per theme',
        'Follow the step-by-step guide on how to prepare, run, and evaluate the activity'
    ]

    return (
        <div className="ue-page-container ue-mid-shadow">
            <UnauthenticatedTemplate>
            <Hero
                title="Home Education for Every Learner"
                text="Education is more than sitting at a desk. Unbound Education helps you to make learning fun to make learning stick."
                showButton={!activeAccount}
                loginRedirect={loginRedirect}
                imageSize="large" />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Hero
                    title="Home Education for Every Learner"
                    text="Education is more than sitting at a desk. Unbound Education helps you to make learning fun to make learning stick."
                    showButton={!activeAccount}
                    loginRedirect={loginRedirect}
                    imageSize="small" />
                {activeAccount ? (
                    <>
                        <FullWidthSubHeading
                            title="Subjects"
                            styleOption={3}
                            dots={4} />
                        <div className="ue-home-subjects">
                            <p>Pick a subject below to get started</p>
                            <button className="ue-primary-button ue-subject-button" onClick={() => navigate('/subject/04c36c04-49d5-4f3e-9a4b-c6436e0fc5b9')}>
                                GCSE History (EdExcel)
                            </button>
                            <button className="ue-primary-button ue-subject-button" onClick={() => navigate('/subject/037ce651-cce4-46ae-9e25-4e62fc395e30')}>
                                GCSE English (AQA)
                            </button>
                            <button className="ue-primary-button ue-subject-button" onClick={() => navigate('/subject/351621af-6c87-4922-86fb-a71494175d60')}>
                                GCSE Citizenship (Edexcel)
                            </button>
                        </div>
                    </>
                ) :
                    <div>
                        <h3 class="ue-get-started-text"><span class="ue-get-started-link" onClick={() => instance.loginRedirect(loginRequest).catch((error) => console.log(error))}>Log in or sign up</span> for free to get started</h3>
                    </div>}
            </AuthenticatedTemplate>
            <p className="ue-home-explanation-text">
                All our activities are aligned to GCSE (UK) curriculum. Whether your learner is sitting them or not we provide a wide range of subject activities to give a breadth of education, not just STEM. The free account gives you access to some of our subjects and resources so why not try it now? We have a range of one-to-one adult and learner activities, small group activities, and solo learner activities.
            </p>
            <UnauthenticatedTemplate>
                <CardGrid
                    cards={cards} />
            </UnauthenticatedTemplate>
            <NumberBox2
                title='How it works'
                items={steps}
                styleOption={0} />
            <UnauthenticatedTemplate>
                <div>
                    <h3 class="ue-get-started-text"><span class="ue-get-started-link" onClick={() => instance.loginRedirect(loginRequest).catch((error) => console.log(error))}>Log in or sign up</span> for free to get started</h3>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};