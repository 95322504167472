import { useState, useCallback, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../authConfig';

export const useAuthenticatedFetch = () => {
    const { instance } = useMsal();
    const activeAccount = useMemo(() => instance.getActiveAccount(), [instance]);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const callEndpoint = async (endpoint, pathParameter, response, requestData, navigate) => {
        // Fetch the data with the token
        const url = `${endpoint}/${pathParameter}`;
        const fetchResponse = await fetch(url, {
            ...requestData,
            headers: {
                'Authorization': `Bearer ${response.idToken}`
            }
        });

        // Check for 400-499 status codes
        if (fetchResponse.status >= 400 && fetchResponse.status < 500) {
            navigate('/login'); // Redirect to login
            throw new Error(`Client error: ${fetchResponse.status}`);
        }

        if (!fetchResponse.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await fetchResponse.json();
        setData(responseData);
    }

    const fetchData = useCallback(async (endpoint, pathParameter, requestData) => {
        if (!activeAccount) {
            console.error('No active account! Verify a user is signed in and setActiveAccount has been called.');
            setError('No active account! Verify a user is signed in and setActiveAccount has been called.');
            return;
        }

        const request = {
            scopes: ['https://unboundeducationapp.b2clogin.com//' + msalConfig.auth.clientId + '.default'],
            account: activeAccount
        };

        try {
            // Try to acquire token silently
            const response = await instance.acquireTokenSilent(request);
            callEndpoint(endpoint, pathParameter, response, requestData);
        } catch (error) {
            if (error.name === "InteractionRequiredAuthError") {
                try {
                    const response = await instance.acquireTokenPopup(request);
                    callEndpoint(endpoint, pathParameter, response, requestData);
                } catch (err) {
                    console.error('Failed to acquire token:', err);
                    setError(`Failed to acquire token: ${err}`);
                }
            } else {
                console.error('Failed to fetch data:', error);
                setError(`Failed to load data: ${error}`);
            }
        }
    }, [instance, activeAccount]); // Dependencies for useCallback

    return { fetchData, data, error };
};