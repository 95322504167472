import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../authConfig';
import DropdownMenu from './DropdownMenu';
import './NavigationBar.css';
import logo from '../images/unbound-logo-icon.png';

export const NavigationBar = () => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
        console.log('activeAccount', activeAccount);
    }

    const unauthenticatedUserMenuItems = [
      { label: 'Sign up or log in', onClick: () => instance.loginRedirect(loginRequest).catch((error) => console.log(error)) }
    ];

    const authenticatedUserMenuItems = [
      { label: 'Edit Profile', onClick: () => {
          if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
          } 
      }},
      { label: 'Sign out', onClick: () => instance.logoutRedirect() }
    ];

    return (
        <>
            <div bg="primary" variant="dark" className="ue-navbar ue-mid-shadow">
                
                <div className="navbar-brand">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Descriptive Alt Text" className="ue-navbar-image" />
                    </a>
                    <h2 className="ue-navbar-title">Unbound Education</h2>
                </div>
                <AuthenticatedTemplate>
                    <DropdownMenu items={authenticatedUserMenuItems} title={activeAccount && activeAccount.name ? activeAccount.name : 'Unknown'} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <DropdownMenu items={unauthenticatedUserMenuItems} title='Log in' />
                </UnauthenticatedTemplate>
            </div>
        </>
    );
};
