import './Footer.css';

export const Footer = () => {
    return (
        <>
            <footer className="ueFooter ue-mid-shadow">
                <center>
                    Contact us: <a href="mailto:andrew@unbound.eduction">andrew@unbound.education</a>
                </center>
            </footer>
        </>
    );
};