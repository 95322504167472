import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch';
import ThemeContainer from '../components/ThemeContainer';
import NumberBox2 from '../components/NumberBox2';
import FullWidthSubHeading from '../components/FullWidthSubHeading';
import { PropagateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

const Subject = () => {
    const { subjectID } = useParams();
    const { fetchData, data, error } = useAuthenticatedFetch();
    const courseEndpoint = `https://unboundeducationapi.azurewebsites.net/api/GetCourse/`;
    const navigate = useNavigate();

    useEffect(() => {
        const loadCourse = async () => {
            await fetchData(courseEndpoint, subjectID, { method: 'GET' }, navigate);
        };

        loadCourse();
    }, [fetchData, subjectID, courseEndpoint, navigate]);

    if (data) {
        console.log('data', data);
    }

    if (error) {
        return <div>Error loading course data: {error}</div>;
    }

    return (
        <>
            {data === null ? (
                <PropagateLoader color="#FC9E36" />
            ) : (
                    <>
                    <div className="ue-page-container ue-mid-shadow">
                        <FullWidthSubHeading
                            title={data?.courseName}
                            styleOption={3}
                            dots={4}
                            isHeader={true} />
                        <p className="ue-section-text">{data?.courseDescription}</p>
                        <NumberBox2
                            title="Aims and Objectives"
                            items={data?.courseAimsAndObjectives}
                                styleOption={0} />
                        </div>
                        {
                            data?.courseName === 'GCSE History' ?
                                <>
                                    <h1 className="ue-subheading">Options</h1>
                                    <p>The History GCSE is split into options. Unlike some subjects, a learner doesn't need to do all of these and should instead pick one from each group of options. Options 10-13 is a group, as are options B1-B4 and options 30-33.</p>
                                </>
                                :
                                <>
                                    <h1 className="ue-subheading">Themes</h1>
                                    <p>The course is split into themes, and those themes are split into topics. To get started pick any that sound interesting and take a look at the activities. Each activity is designed to help the learner explore that topic in interesting ways.</p>
                                </>    
                        }
                        
                        {data?.courseStructure?.themes.map((theme, index) => (
                            <ThemeContainer key={theme.id}
                                themeTitle={theme.themeTitle}
                                themeDescription={theme.description} y
                                topics={theme.topics}
                                styleOption={index % 2} />
                                            
                        ))}
                    </>
            )}
        </>
    );
};

export default Subject;
