import React from 'react';
import './FullWidthSubHeading.css'; // Import the corresponding CSS file

const FullWidthSubHeading = ({ title, styleOption, dots, isHeader }) => {
    // Define header colors based on the style option
    const headerColorClasses = ['ue-yellow-background', 'ue-orange-background', 'ue-light-blue-background', 'ue-mid-blue-background', 'ue-teal-background']; // Example colors
    const headerColorClass = headerColorClasses[styleOption] || headerColorClasses[0]; // Default to the first color if out of range

    return (
        <div className={"ue-fwsh-header " + headerColorClass + ' ' + (isHeader ? 'ue-fw-header-top' : '')}>
            <h2 className="ue-fwsh-title">{title}</h2>
            <span className="ue-dots-container">
                {Array.from({ length: dots }).map((_, i) => (
                    <div key={i} className="dot"></div>
                ))}
            </span>
        </div>
    );
};

export default FullWidthSubHeading;
